import React from 'react';
import advocateProfilePic from '../../../assets/images/advocates-details/charishma.jpg';

const AdvocateCharishmaDetails = () => {
  return (
    <section className='team-details'>
      <div className='container'>
        <div className='row justify-content-between'>
          <div className='col-lg-5'>
            <div className='team-details__content'>
              <h2 className='team-details__title'>Read my story</h2>
              <p className='team-details__text'>
                Ms. Charishma Atla have completed her under graduation in
                Accounting from University of Evansville, Indiana (USA). She
                pursued her LLB from O.P. Jindal Global University, Delhi. She
                enrolled as an advocate in the year 2022. She is specialised in
                the areas of Civil and Corporate laws. She is currently
                practicing at the Hon’ble High Court of Telangana, Civil Courts,
                and Criminal Courts at Hyderabad.
              </p>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='team-one__single'>
              <div className='team-one__image'>
                <img src={advocateProfilePic} alt='' />
              </div>
              <div className='team-one__content'>
                <h2 className='team-one__name'>Ms. Charishma Atla, LLB.</h2>
                <p className='team-one__designation'>Advocate</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdvocateCharishmaDetails;
